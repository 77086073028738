import React from "react";

const HowItWorks = () => {
  return (
    <section className="mt-2">
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-xl-6 col-lg-10 col-md-12 col-sm-12 mb-5 animate__animated"
            data-animate="animate__fadeIn"
          >
            <div className="sec-heading center">
              <div className="d-inline-flex px-4 py-1 rounded-5 text-info bg-light-info font--medium mb-2">
                <span>Development Proccess</span>
              </div>
              <h2>How it works?</h2>
              <p className="fs-6 mb-4">
                At Afterdoor Solutions, we follow a meticulous development
                process to ensure your vision becomes a reality. Here's how we
                bring your ideas to life:
              </p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center gy-xl-5 gy-lg-4 gy-5 gx-xl-5 gx-lg-4 gx-3">
          <div
            class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 animate__animated"
            data-animate="animate__fadeInLeft"
          >
            <div className="killar-benifits-wrap text-center">
              <div className="how-icon px-4 py-4 d-inline-flex align-items-center justify-content-center fs-1 bg-light-success text-success rounded-circle">
                <img src="/assets/icons/idea.png" alt="idea" />
              </div>
              <div className="benifits-title mt-3 mb-3">
                <h4 className="fs-5">Idea</h4>
              </div>
              <div className="benifits-desc">
                <p className="mb-0">
                  Your journey starts with sharing your ideas with us. We listen
                  carefully to your concepts and requirements, laying the
                  foundation for a successful project.
                </p>
              </div>
            </div>
          </div>
          <div
            class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 animate__animated"
            data-animate="animate__fadeInUp"
          >
            <div className="killar-benifits-wrap text-center">
              <div className="how-icon px-4 py-4 d-inline-flex align-items-center justify-content-center fs-1 bg-light-warning text-warning rounded-circle">
                <img src="/assets/icons/concept.png" alt="Conceptualization" />
              </div>
              <div className="benifits-title mt-3 mb-3">
                <h4 className="fs-5">Conceptualization</h4>
              </div>
              <div className="benifits-desc">
                <p className="mb-0">
                  Our expert team takes your ideas and refines them into a clear
                  and actionable concept. We brainstorm and explore
                  possibilities, ensuring your project aligns with your goals.
                </p>
              </div>
            </div>
          </div>
          <div
            class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 animate__animated"
            data-animate="animate__fadeInUp"
          >
            <div className="killar-benifits-wrap text-center">
              <div className="how-icon px-4 py-4 d-inline-flex align-items-center justify-content-center fs-1 bg-light-purple text-purple rounded-circle">
                <img src="/assets/icons/planning.png" alt="Plan & Strategize" />
              </div>
              <div className="benifits-title mt-3 mb-3">
                <h4 className="fs-5">Plan & Strategize</h4>
              </div>
              <div className="benifits-desc">
                <p className="mb-0">
                  With a solid concept in hand, we develop a comprehensive plan.
                  We strategize every detail, from technology choices to project
                  timelines, ensuring a smooth path to success.
                </p>
              </div>
            </div>
          </div>
          <div
            class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 animate__animated"
            data-animate="animate__fadeInUp"
          >
            <div className="killar-benifits-wrap text-center">
              <div className="how-icon px-4 py-4 d-inline-flex align-items-center justify-content-center fs-1 bg-light-danger text-danger rounded-circle">
                <img src="/assets/icons/coding.png" alt="Development" />
              </div>
              <div className="benifits-title mt-3 mb-3">
                <h4 className="fs-5">Development</h4>
              </div>
              <div className="benifits-desc">
                <p className="mb-0">
                  The development phase begins, where our talented team
                  transforms your concept into a functional reality. We embrace
                  agile methodologies to adapt to evolving needs.
                </p>
              </div>
            </div>
          </div>
          <div
            class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 animate__animated"
            data-animate="animate__fadeInRight"
          >
            <div className="killar-benifits-wrap text-center">
              <div className="how-icon px-4 py-4 d-inline-flex align-items-center justify-content-center fs-1 bg-light-royal text-danger rounded-circle">
                <img src="/assets/icons/rocket.png" alt="Launch" />
              </div>
              <div className="benifits-title mt-3 mb-3">
                <h4 className="fs-5">Launch</h4>
              </div>
              <div className="benifits-desc">
                <p className="mb-0">
                  Finally, the moment you've been waiting for – we launch your
                  project. Our testing ensures a experience for your audience.
                  Your project is live, and your journey to success begins.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
