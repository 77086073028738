import React from "react";

const Navigation = ({ menuClass }) => {
  const currentUrl = window.location.pathname;
  console.log(currentUrl);
  const servicesLinks = ["/web-development", "/services"];

  return (
    <>
      {/* Start Navigation */}
      <div className={`header ${menuClass} dark`}>
        <div className="container">
          <nav id="navigation" className="navigation navigation-landscape">
            <div className="nav-header">
              <a className="nav-brand" href="/">
                <img
                  src="/assets/img/logos/ads-logo-green.png"
                  className="logo"
                  alt="Afterdoor Solutions"
                />
              </a>
              <div className="nav-toggle" />
              <div className="mobile_nav">
                <ul>
                  <li>
                    <a href="#contact-us" className="btn btn-success">
                      Contact Us
                      <i className="fa-regular fa-circle-right ms-2" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="nav-menus-wrapper"
              style={{ transitionProperty: "none" }}
            >
              <ul className="nav-menu">
                <li className={`${currentUrl === "/" && "active"}`}>
                  <a href={"/"}>Home</a>
                </li>
                <li className={`${currentUrl === "/about-us" && "active"}`}>
                  <a href={"/about-us"}>About Us</a>
                </li>
                <li
                  className={`${
                    servicesLinks.includes(currentUrl) ? "active" : ""
                  }`}
                >
                  <a href="#!">
                    Services
                    <span className="submenu-indicator" />
                  </a>
                  <ul className="nav-dropdown nav-submenu">
                    <li>
                      <a href="/web-development">Web Development</a>
                    </li>
                    <li>
                      <a href="/design">Design</a>
                    </li>
                    <li>
                      <a href="/">Mobile Application</a>
                    </li>
                    <li>
                      <a href="/">Quality Assurance</a>
                    </li>
                    <li>
                      <a href="/">Research & Development</a>
                    </li>
                    <li>
                      <a href="/">Cloud Management</a>
                    </li>
                    <li>
                      <a href="/">Cyber Security</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="/">Case Studies</a>
                </li>
                <li className={`${currentUrl === "/jobs" && "active"}`}>
                  <a href="/jobs">Join Our Team</a>
                </li>
              </ul>
              <ul className="nav-menu nav-menu-social align-to-right">
                <li className="list-buttons ms-2">
                  <a href="#contact-us" className="bg-success">
                    Contact Us
                    <i className="fa-regular fa-circle-right ms-2" />
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

      {/* End Navigation */}
    </>
  );
};

export default Navigation;
