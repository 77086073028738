import React from "react";
import Navigation from "../Sections/Navigation";
import Footer from "../Sections/Footer";
import Contact from "./Contact";
import { Helmet } from "react-helmet";
const Jobs = () => {
  return (
    <div>
      <Helmet>
        <title>Join Our Team - Afterdoor Solutions</title>
        <meta
          name="description"
          content="Explore exciting career opportunities and join our talented team. Discover how you can contribute to our mission and grow your career with us."
        />
        <meta
          name="keywords"
          content="php jobs, node.js jobs, full stack devloper,join my team, careers, job openings, employment opportunities, work with us, hiring, job vacancies, join us, career opportunities, job listings, join our team"
        />
      </Helmet>
      <div className="clearfix" />
      <Navigation />
      <section
        className="bg-cover"
        style={{ background: "url(/assets/img/jobs.webp)no-repeat" }}
        data-overlay={6}
      >
        <div className="ht-80" />
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-12">
              <h1 className="text-light mb-4">
                Explore Opportunities to
                <br />
                Grow with Our Team
              </h1>
              <p className="fs-5 fw-light text-light mb-4">
                As part of our team, you'll have the opportunity to collaborate
                with like-minded professionals, tackle exciting challenges, and
                chart a path to a fulfilling career. Explore our open positions
                and join us in shaping the future.
              </p>
              <a href="#openings" className="btn btn-primary px-5">
                Explore Openings
              </a>
            </div>
          </div>
        </div>
        <div className="ht-80" />
      </section>

      <section className="bg-light-info position-relative" id="openings">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-lg-12">
              <h5 className="mb-3">Explore All Openings</h5>
            </div>
          </div>
          <div className="row justify-content-center g-4 mt-2">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <div className="card border-0 rounded-3 py-4 px-4">
                <div className="card-thumb mb-3">
                  {/* <img
                    src="assets/img/faq-1.png"
                    className="img-fluid"
                    width={70}
                    alt="img"
                  /> */}
                </div>
                <div className="card-captoio mb-4">
                  <h5>Full Stack Developer</h5>
                  <p className="text-muted mb-0">
                    Seeking a Full Stack Developer with a passion for crafting
                    exceptional web solutions, proficient in both front-end and
                    back-end technologies, and experienced in user-centric
                    digital experiences.
                  </p>
                </div>
                <div className="card-links">
                  <a
                    href="https://forms.gle/kDSca4Uzbs3m7poNA"
                    className="text-primary font--medium"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apply Now
                    <i className="fa-regular fa-circle-right ms-2" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <div className="card border-0 rounded-3 py-4 px-4">
                <div className="card-thumb mb-3"></div>
                <div className="card-captoio mb-4">
                  <h5>PHP Developer</h5>
                  <p className="text-muted mb-0">
                    PHP Developer: Looking for a skilled PHP Developer with
                    expertise in server-side scripting, database integration,
                    and a knack for building dynamic web applications & API's.
                  </p>
                </div>
                <div className="card-links">
                  <a
                    href="https://forms.gle/kDSca4Uzbs3m7poNA"
                    className="text-primary font--medium"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apply Now
                    <i className="fa-regular fa-circle-right ms-2" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <div className="card border-0 rounded-3 py-4 px-4">
                <div className="card-thumb mb-3"></div>
                <div className="card-captoio mb-4">
                  <h5>Front End Developer</h5>
                  <p className="text-muted mb-0">
                    Front End Developer: Seeking a Front End Developer skilled
                    in HTML, CSS, and JavaScript, React.js, Vue.js, Next.js with
                    a creative eye for designing intuitive, responsive user
                    interfaces."
                  </p>
                </div>
                <div className="card-links">
                  <a
                    href="https://forms.gle/kDSca4Uzbs3m7poNA"
                    className="text-primary font--medium"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apply Now
                    <i className="fa-regular fa-circle-right ms-2" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <div className="card border-0 rounded-3 py-4 px-4">
                <div className="card-thumb mb-3"></div>
                <div className="card-captoio mb-4">
                  <h5>Designer (UI)</h5>
                  <p className="text-muted mb-0">
                    Join our creative team as a UI Designer, where your artistic
                    flair meets user-centric design. Craft visually captivating
                    interfaces that elevate user experiences and leave a lasting
                    impression.
                  </p>
                </div>
                <div className="card-links">
                  <a
                    href="https://forms.gle/kDSca4Uzbs3m7poNA"
                    className="text-primary font--medium"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apply Now
                    <i className="fa-regular fa-circle-right ms-2" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <div className="card border-0 rounded-3 py-4 px-4">
                <div className="card-thumb mb-3"></div>
                <div className="card-captoio mb-4">
                  <h5>SEO Analyst</h5>
                  <p className="text-muted mb-0">
                    Embark on a dynamic role as an SEO Analyst, where you'll
                    shape online visibility and organic growth and drive higher
                    rankings, increased traffic, and enhanced digital presence.
                  </p>
                </div>
                <div className="card-links">
                  <a
                    href="https://forms.gle/kDSca4Uzbs3m7poNA"
                    className="text-primary font--medium"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apply Now
                    <i className="fa-regular fa-circle-right ms-2" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <div className="card border-0 rounded-3 py-4 px-4">
                <div className="card-thumb mb-3"></div>
                <div className="card-captoio mb-4">
                  <h5>QA Engineer</h5>
                  <p className="text-muted mb-0">
                    Join our team as a QA Engineer, ensuring product excellence
                    through meticulous testing and quality assurance practices.
                    Play a pivotal role in delivering flawless software
                    solutions.
                  </p>
                </div>
                <div className="card-links">
                  <a
                    href="https://forms.gle/kDSca4Uzbs3m7poNA"
                    className="text-primary font--medium"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apply Now
                    <i className="fa-regular fa-circle-right ms-2" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
              <div className="card border-0 rounded-3 py-4 px-4">
                <div className="card-thumb mb-3"></div>
                <div className="card-captoio mb-4">
                  <h5>Mobile Development</h5>
                  <p className="text-muted mb-0">
                    Be part of our mobile development team, creating
                    cutting-edge apps that redefine user experiences. Shape the
                    future of mobile technology with innovation and expertise.
                  </p>
                </div>
                <div className="card-links">
                  <a
                    href="https://forms.gle/kDSca4Uzbs3m7poNA"
                    className="text-primary font--medium"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Apply Now
                    <i className="fa-regular fa-circle-right ms-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="clearfix" />
      <Contact />
      <div className="clearfix" />

      <Footer />
    </div>
  );
};

export default Jobs;
