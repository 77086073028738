import React from "react";
import Navigation from "../../Sections/Navigation";
import Footer from "../../Sections/Footer";
import Contact from "../Contact";
import { Helmet } from "react-helmet";
import HowItWorks from "../sections/HowItWorks";

const Design = () => {
  return (
    <div>
      <Helmet>
        <title>Design - Afterdoor Solutions</title>
        <meta
          name="description"
          content="Explore exciting career opportunities and join our talented team. Discover how you can contribute to our mission and grow your career with us."
        />
        <meta
          name="keywords"
          content="php jobs, node.js jobs, full stack devloper,join my team, careers, job openings, employment opportunities, work with us, hiring, job vacancies, join us, career opportunities, job listings, join our team"
        />
      </Helmet>
      <div className="clearfix" />
      <Navigation />

      <section
        className="bg-cover"
        style={{ background: "url(//assets/logos/ui.webp)no-repeat" }}
        data-overlay={6}
      >
        <div className="ht-80" />
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-12">
              <h1 className="text-light mb-4">
                Crafting Exceptional User
                <br />
                Experiences
              </h1>
              <p className="fs-5 fw-light text-light mb-4">
                Unlock a world of exceptional digital experiences and ensure
                lasting user satisfaction with our comprehensive UI/UX design
                services. From concept to execution, we craft interfaces that
                captivate, engage, and elevate your brand's online presence.
              </p>
              <a href="#contact-us" className="btn btn-primary px-5">
                Let's Talk
              </a>
            </div>
          </div>
        </div>
        <div className="ht-80" />
      </section>

      <section className="mt-2">
        <div className="container">
          <div className="position-relative rounded-5 bg-light-info px-xl-5 px-lg-4 px-md-4 py-xl-5 py-lg-4 py-md-4 p-4">
            <div className="row align-items-center">
              <div className="col-xxl-5 col-xl-4 col-lg-4 col-md-12">
                <div className="row justify-content-center row-cols-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 g-xl-4 g-lg-4 g-3">
                  <div className="col">
                    <div className="soft-block d-flex align-items-center justify-content-center bg-white rounded-2 px-4 py-4">
                      <img
                        src="/assets/logos/adobe-photoshop.svg"
                        className="img-fluid"
                        width={80}
                        alt="software"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="soft-block d-flex align-items-center justify-content-center bg-white rounded-2 px-4 py-4">
                      <img
                        src="/assets/logos/adobe-illustrator.svg"
                        className="img-fluid"
                        width={80}
                        alt="software"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="soft-block d-flex align-items-center justify-content-center bg-white rounded-2 px-4 py-4">
                      <img
                        src="/assets/logos/adobe-lightroom.svg"
                        className="img-fluid"
                        width={80}
                        alt="software"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="soft-block d-flex align-items-center justify-content-center bg-white rounded-2 px-4 py-4">
                      <img
                        src="/assets/logos/adobe-xd.svg"
                        className="img-fluid"
                        width={80}
                        alt="software"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="soft-block d-flex align-items-center justify-content-center bg-white rounded-2 px-4 py-4">
                      <img
                        src="/assets/logos/mockflow-icon.svg"
                        className="img-fluid"
                        width={80}
                        alt="software"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="soft-block d-flex align-items-center justify-content-center bg-white rounded-2 px-4 py-4">
                      <img
                        src="/assets/logos/figma.svg"
                        className="img-fluid"
                        width={80}
                        alt="software"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex align-items-center justify-content-center bg-white rounded-2 px-4 py-4">
                      <img
                        src="/assets/logos/zeplin.svg"
                        className="img-fluid"
                        width={80}
                        alt="software"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex align-items-center justify-content-center bg-white rounded-2 px-4 py-4">
                      <img
                        src="/assets/logos/sketch.svg"
                        className="img-fluid"
                        width={80}
                        alt="software"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-7 col-lg-7 col-md-12 offset-xxl-1 offset-xl-1 offset-lg-1">
                <div className="position-relative text-caption-exliok11 pt-xl-0 pt-lg-0 pt-md-4 pt-4">
                  <h2 className="fs-1 mb-4 lh-base">
                    We Use High Quality Software
                    <br className="d-none d-lg-inline" />
                    For Development
                  </h2>
                  <p className="fs-5 fw-light">
                    hese cases are perfectly simple and easy to distinguish. In
                    a free hour, when our power of choice is untrammelled and
                    when nothing prevents our being able to do what we like
                    best, every pleasure is to welcomed and every pain avoided.
                    But in certain circumstances.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative bg-light-seegreen">
        <div className="container py-4 py-lg-4">
          <div className="row">
            <div className="col-lg-7">
              <div className="row px-3 py-4">
                <div className="col-sm-6 mb-3">
                  <div className="pe-md-3">
                    <span className="text-success fs-1 mb-4">
                      <img
                        src="/assets/icons/web-design.png"
                        alt="Web Design"
                        className="w-20 mb-2"
                      />
                    </span>
                    <h5>Web Design</h5>
                    <p>
                      Our web design service transforms ideas into captivating
                      websites, balancing aesthetics and functionality to engage
                      users effectively.
                    </p>
                  </div>
                </div>
                {/* End Col */}
                <div className="col-sm-6 mb-3">
                  <div className="pe-md-3">
                    <span className="text-success fs-1 mb-4">
                      <img
                        src="/assets/icons/user-interface.png"
                        alt="Web Design"
                        className="w-20 mb-2"
                      />
                    </span>
                    <h5>App Design</h5>
                    <p>
                      Crafting exceptional mobile app experiences, we merge
                      aesthetics and usability to ensure your app stands out and
                      satisfies users.
                    </p>
                  </div>
                </div>
                {/* End Col */}
              </div>
              {/* End Row */}
              <div className="row px-3 py-4">
                <div className="col-sm-6 mb-3">
                  <div className="pe-md-3">
                    <span className="text-success fs-1 mb-4">
                      <img
                        src="/assets/icons/ui.png"
                        alt="Web Design"
                        className="w-20 mb-2"
                      />
                    </span>
                    <h5>UI/UX</h5>
                    <p>
                      Elevate digital interactions with our user-focused design
                      approach. We create intuitive interfaces, providing
                      seamless and enjoyable user experiences.
                    </p>
                  </div>
                </div>
                {/* End Col */}
                <div className="col-sm-6 mb-3">
                  <div className="pe-md-3">
                    <span className="text-success fs-1 mb-4">
                      <img
                        src="/assets/icons/wireframe.png"
                        alt="Web Design"
                        className="w-20 mb-2"
                      />
                    </span>
                    <h5>Wireframing</h5>
                    <p>
                      Our meticulous wireframing process maps out your project's
                      structure, functionality, and user flow, setting the
                      foundation for successful development and user
                      satisfaction.
                    </p>
                  </div>
                </div>
                {/* End Col */}
              </div>
              {/* End Row */}
            </div>
            {/* End Col */}
          </div>
          {/* End Row */}
        </div>
        <div
          className="d-none d-lg-block col-md-5 position-absolute bg-cover top-0 end-0 h-100 rounded-start-5"
          style={{ backgroundImage: "url(/assets/img/design.webp)" }}
        />
        <div className="d-lg-none px-4">
          <img
            className="img-fluid rounded-3"
            src="/assets/img/design.webp"
            alt="Description"
          />
        </div>
      </section>

      <HowItWorks />
      <div className="clearfix" />
      <Contact />
      <div className="clearfix" />

      <Footer />
    </div>
  );
};

export default Design;
