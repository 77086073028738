import React from "react";
import Navigation from "../../Sections/Navigation";
import Footer from "../../Sections/Footer";
import Contact from "./../Contact";
import { Helmet } from "react-helmet";
import HowItWorks from "../sections/HowItWorks";
const WebDevelopment = () => {
  return (
    <div>
      <Helmet>
        <title>Web Development - Afterdoor Solutions</title>
        <meta
          name="description"
          content="Explore exciting career opportunities and join our talented team. Discover how you can contribute to our mission and grow your career with us."
        />
        <meta
          name="keywords"
          content="php jobs, node.js jobs, full stack devloper,join my team, careers, job openings, employment opportunities, work with us, hiring, job vacancies, join us, career opportunities, job listings, join our team"
        />
      </Helmet>
      <div className="clearfix" />
      <Navigation />

      <section
        className="bg-cover"
        style={{
          background: "url(//assets/img/web-development.webp)no-repeat",
        }}
        data-overlay={6}
      >
        <div className="ht-80" />
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-12">
              <h1
                className="animate__animated text-light mb-4"
                data-animate="animate__fadeInUp"
              >
                Elevate Your Brand with Expert
                <br />
                Web Development Solutions for the Digital Age
              </h1>
              <p
                className="animate__animated  fs-5 fw-light text-light mb-4"
                data-animate="animate__fadeInUp"
              >
                At Afterdoor Solutions, our seasoned web development team
                combines cutting-edge technology with creative design to deliver
                bespoke websites that captivate your audience, drive engagement,
                and boost your online presence. Explore our comprehensive web
                development services to unlock your brand's full digital
                potential.
              </p>
              <a
                href="#contact-us"
                className="animate__animated btn btn-primary px-5"
                data-animate="animate__fadeInUp"
              >
                Let's Discuss
              </a>
            </div>
          </div>
        </div>
        <div className="ht-80" />
      </section>

      <section>
        <div className="container">
          <div className="row align-items-center justify-content-between gx-4 gy-3">
            <div
              className="col-xl-5 col-lg-5 col-md-6 animate__animated"
              data-animate="animate__fadeInLeft"
            >
              <div className="cliorx-wrap">
                <div className="label text-success bg-light-success d-inline-flex rounded-4 mb-2 font--medium">
                  <span>Web Development</span>
                </div>
                <div className="cliorx-caps">
                  <h2 className="lh-base mb-3">
                    We Use High Quality Software
                    <br />
                    For Development
                  </h2>
                  <p className="fs-5 fw-light">
                    At Afterdoor Solutions, our commitment to exceptional design
                    starts with our choice of UI/UX tools. We harness
                    cutting-edge, user-centric software, ensuring precision,
                    security, and innovation in every project.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-6 animate__animated"
              data-animate="animate__fadeInRight"
            >
              <div className="row justify-content-center row-cols-md-4 row-cols-3 g-xl-3 g-lg-3 g-3">
                <div className="col">
                  <div className="soft-block border gray text-center rounded-4 py-4">
                    <img
                      src="/assets/logos/php.svg"
                      className="img-fluid"
                      width={80}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="soft-block border gray text-center rounded-4 py-4">
                    <img
                      src="/assets/logos/laravel.svg"
                      className="img-fluid"
                      width={80}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="soft-block border gray text-center rounded-4 py-4">
                    <img
                      src="/assets/logos/nodejs-icon.svg"
                      className="img-fluid"
                      width={80}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="soft-block border gray text-center rounded-4 py-4">
                    <img
                      src="/assets/logos/html-5.svg"
                      className="img-fluid"
                      width={80}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="soft-block border gray text-center rounded-4 py-4">
                    <img
                      src="/assets/logos/react.svg"
                      className="img-fluid"
                      width={80}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="soft-block border gray text-center rounded-4 py-4">
                    <img
                      src="/assets/logos/javascript.svg"
                      className="img-fluid"
                      width={80}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="soft-block border gray text-center rounded-4 py-4">
                    <img
                      src="/assets/logos/codeigniter.svg"
                      className="img-fluid"
                      width={80}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="soft-block border gray text-center rounded-4 py-4">
                    <img
                      src="/assets/logos/angular-icon.svg"
                      className="img-fluid"
                      width={80}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="soft-block border gray text-center rounded-4 py-4">
                    <img
                      src="/assets/logos/vue.svg"
                      className="img-fluid"
                      width={80}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="soft-block border gray text-center rounded-4 py-4">
                    <img
                      src="/assets/logos/wordpress.svg"
                      className="img-fluid"
                      width={80}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="soft-block border gray text-center rounded-4 py-4">
                    <img
                      src="/assets/logos/yii.svg"
                      className="img-fluid"
                      width={80}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="soft-block border gray text-center rounded-4 py-4">
                    <img
                      src="/assets/logos/pwa.svg"
                      className="img-fluid"
                      width={80}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-dark">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-xxl-5 col-xl-4 col-lg-4 animate__animated"
              data-animate="animate__fadeInLeft"
            >
              <div className="position-relative py-lg-0 pb-4">
                <h2 className="fs-1 mb-3 mb-lg-3 text-light">
                  Providing Exceptional Web Development Services
                </h2>
                <p className="text-light fs-5 fw-light">
                  At Afterdoor Solutions, we take pride in being the forefront
                  web development company, dedicated to crafting tailor-made and
                  adaptable web solutions. By partnering with us, you'll unlock
                  a world of web services meticulously designed to meet the
                  unique demands of your business. Explore the possibilities of
                  our expertise and empower your digital journey with us.
                </p>
                <ul className="list-unstyled d-none d-lg-block pb-3 mb-3 mb-lg-4">
                  <li className="d-flex pt-2 fs-6 text-light">
                    <i className="fa-solid fa-check fs-5 text-success mt-n1 me-2" />
                    Innovation
                  </li>
                  <li className="d-flex pt-2 fs-6 text-light">
                    <i className="fa-solid fa-check fs-5 text-success mt-n1 me-2" />
                    Scalability
                  </li>
                  <li className="d-flex pt-2 fs-6 text-light">
                    <i className="fa-solid fa-check fs-5 text-success mt-n1 me-2" />
                    User-Centric
                  </li>
                  <li className="d-flex pt-2 fs-6 text-light">
                    <i className="fa-solid fa-check fs-5 text-success mt-n1 me-2" />
                    Security
                  </li>
                  <li className="d-flex pt-2 fs-6 text-light">
                    <i className="fa-solid fa-check fs-5 text-success mt-n1 me-2" />
                    Reliability
                  </li>
                  <li className="d-flex pt-2 fs-6 text-light">
                    <i className="fa-solid fa-check fs-5 text-success mt-n1 me-2" />
                    Transparency
                  </li>
                  <li className="d-flex pt-2 fs-6 text-light">
                    <i className="fa-solid fa-check fs-5 text-success mt-n1 me-2" />
                    Cost-Effectiveness
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-7 col-lg-7 col-md-12 offset-xxl-1 offset-xl-1 offset-lg-1">
              <div className="row gy-xl-5 gy-lg-5 gy-4 gx-4">
                <div
                  className="col-xl-4 col-lg-4 col-md-4 animate__animated"
                  data-animate="animate__fadeInUp"
                >
                  <div className="card border-0 bg-transparent">
                    <div className="position-relative mb-3 ">
                      <div className="text-success circle d-inline-flex square--80">
                        <img
                          src="/assets/icons/app-development.png"
                          alt="Web Development"
                          className="w-16 mb-2"
                        />
                      </div>
                    </div>
                    <div className="crd-srv">
                      <h4 className="font--bold text-light mb-2">
                        Web Development
                      </h4>
                      <p className="text-light opacity-75 fw-light fs-6 mb-0">
                        We create websites that leave a lasting impression,
                        blending design and functionality for an exceptional
                        online presence.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-4 col-lg-4 col-md-4 animate__animated"
                  data-animate="animate__fadeInUp"
                >
                  <div className="position-relative mb-3">
                    <div className=" text-warning circle d-inline-flex square--80">
                      <img
                        src="/assets/icons/software.png"
                        alt="Frameworks & CMS"
                        className="w-16 mb-2"
                      />
                    </div>
                  </div>
                  <div className="crd-srv">
                    <h4 className="font--bold text-light mb-2">
                      Frameworks & CMS
                    </h4>
                    <p className="text-light opacity-75 fw-light fs-6 mb-0">
                      Empower your website with the efficiency and flexibility
                      of robust frameworks and Content Management Systems (CMS)
                      for seamless content control.
                    </p>
                  </div>
                </div>
                <div
                  className="col-xl-4 col-lg-4 col-md-4 animate__animated"
                  data-animate="animate__fadeInUp"
                >
                  <div className="position-relative mb-3">
                    <div className=" text-purple circle d-inline-flex square--80">
                      <img
                        src="/assets/icons/api.png"
                        alt="Custom API Development"
                        className="w-16 mb-2"
                      />
                    </div>
                  </div>
                  <div className="crd-srv">
                    <h4 className="font--bold text-light mb-2">
                      Custom API Development
                    </h4>
                    <p className="text-light opacity-75 fw-light fs-6 mb-0">
                      Tailored solutions to connect and streamline data flow
                      across your web applications, enhancing performance and
                      functionality.
                    </p>
                  </div>
                </div>
                <div
                  className="col-xl-4 col-lg-4 col-md-4 animate__animated"
                  data-animate="animate__fadeInUp"
                >
                  <div className="position-relative mb-3">
                    <div className=" text-info circle d-inline-flex square--80">
                      <img
                        src="/assets/icons/erp.png"
                        alt="ERP & Integrations"
                        className="w-16 mb-2"
                      />
                    </div>
                  </div>
                  <div className="crd-srv">
                    <h4 className="font--bold text-light mb-2">
                      ERP & Integrations
                    </h4>
                    <p className="text-light opacity-75 fw-light fs-6 mb-0">
                      Seamlessly connect and optimize your business processes
                      with our ERP solutions and integration expertise.
                    </p>
                  </div>
                </div>
                <div
                  className="col-xl-4 col-lg-4 col-md-4 animate__animated"
                  data-animate="animate__fadeInUp"
                >
                  <div className="position-relative mb-3">
                    <div className="text-info circle d-inline-flex square--80">
                      <img
                        src="/assets/icons/shopping.png"
                        alt="E-Commerce Solutions"
                        className="w-16 mb-2"
                      />
                    </div>
                  </div>
                  <div className="crd-srv">
                    <h4 className="font--bold text-light mb-2">
                      E-Commerce Solutions
                    </h4>
                    <p className="text-light opacity-75 fw-light fs-6 mb-0">
                      Elevate your online retail presence with tailored
                      e-commerce solutions that drive sales and enhance user
                      experiences.
                    </p>
                  </div>
                </div>
                <div
                  className="col-xl-4 col-lg-4 col-md-4 animate__animated"
                  data-animate="animate__fadeInUp"
                >
                  <div className="position-relative mb-3">
                    <div className="text-info circle d-inline-flex square--80">
                      <img
                        src="/assets/icons/support.png"
                        alt="Maintenance & Support"
                        className="w-16 mb-2"
                      />
                    </div>
                  </div>
                  <div className="crd-srv">
                    <h4 className="font--bold text-light mb-2">
                      Maintenance & Support
                    </h4>
                    <p className="text-light opacity-75 fw-light fs-6 mb-0">
                      We offer ongoing support and maintenance to ensure your
                      web solutions stay reliable and up-to-date.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <HowItWorks />

      <div className="clearfix" />
      <Contact />
      <div className="clearfix" />

      <Footer />
    </div>
  );
};

export default WebDevelopment;
