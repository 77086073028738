import React from "react";
import Navigation from "../Sections/Navigation";
import Footer from "../Sections/Footer";
import Contact from "./Contact";

const Home = () => {
  return (
    <div>
      <div className="clearfix" />
      <Navigation menuClass={"header-transparent"} />
      <div className="image-cover hero-header bg-light-success position-relative">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="elcoss-excort mt-xl-5 mt-lg-4">
                <div className="bg-warning text-light rounded-2 px-3 py-2 d-inline-flex font--medium mb-2">
                  <span>All in One Solutions</span>
                </div>
                <h1 className="mb-4">
                  {/* <span className="font--medium">ADS Provide Complete </span> */}
                  <br />
                  Smart Solutions for <br />
                  Smarter Businesses.
                  <br />
                </h1>
                <p className="fs-6">
                  Ignite Your Business Potential with Next-Generation Software
                  and Mobile App Solutions, Tailored to Drive Success in the
                  Digital Era.
                </p>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 offset-lg-1 offset-xl-1">
              <div className="position-relative">
                <img
                  className="d-block position-relative z-2 img-fluid exlop-min"
                  src="assets/img/header.webp"
                  style={{ borderRadius: "12rem 2rem 6rem 2rem" }}
                  alt="Afterdoor Solutions"
                />
                <div className="position-absolute top-0 z-3 start-5">
                  <img
                    src="assets/img/img-1.png"
                    className="img-fluid"
                    width={120}
                    alt="icon"
                  />
                </div>
                <div className="position-absolute bottom-0 z-3 end-0">
                  <img
                    src="assets/img/img-3.png"
                    className="img-fluid"
                    width={150}
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============================ Hero Banner End ================================== */}

      <section className="min bg-success">
        <div className="container">
          <div className="row align-items-center justify-content-center row-cols-3 row-cols-md-6 gx-4 gy-3">
            <div className="col">
              <figure className="single-brand thumb-figure px-lg-3">
                <img
                  src="assets/img/brand/logo-light-1.png"
                  className="img-fluid opacity-50"
                  alt=""
                />
              </figure>
            </div>
            <div className="col">
              <figure className="single-brand thumb-figure px-lg-3">
                <img
                  src="assets/img/brand/logo-light-2.png"
                  className="img-fluid opacity-50"
                  alt=""
                />
              </figure>
            </div>
            <div className="col">
              <figure className="single-brand thumb-figure px-lg-3">
                <img
                  src="assets/img/brand/logo-light-3.png"
                  className="img-fluid opacity-50"
                  alt=""
                />
              </figure>
            </div>
            <div className="col">
              <figure className="single-brand thumb-figure px-lg-3">
                <img
                  src="assets/img/brand/logo-light-4.png"
                  className="img-fluid opacity-50"
                  alt=""
                />
              </figure>
            </div>
            <div className="col">
              <figure className="single-brand thumb-figure px-lg-3">
                <img
                  src="assets/img/brand/logo-light-5.png"
                  className="img-fluid opacity-50"
                  alt=""
                />
              </figure>
            </div>
            <div className="col">
              <figure className="single-brand thumb-figure px-lg-3">
                <img
                  src="assets/img/brand/logo-light-6.png"
                  className="img-fluid opacity-50"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <div className="clearfix" />
      {/* ============================ Our Partners End ================================== */}
      {/* ============================ High Class Features Start ================================== */}
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-5 col-xl-4 col-lg-4">
              <div className="position-relative">
                <h2 className="fs-1 mb-3 mb-lg-3">What we do.</h2>
                <p className="fs-5 fw-light fs-mob">
                  Our collaborative approach, combined with our comprehensive
                  suite of services, ensures a flawless integration of your
                  ideas into a product that delivers an exceptional user
                  experience, captivating your audience with its seamless
                  functionality.
                </p>
                <ul className="list-unstyled d-none d-lg-block pb-3 mb-3 mb-lg-4">
                  <li className="d-flex pt-2 fs-6">
                    <i className="fa-solid fa-check fs-5 text-info mt-n1 me-2" />
                    Collaboratively defining, designing, and developing
                    transformative user experiences.
                  </li>
                  <li className="d-flex pt-2 fs-6">
                    <i className="fa-solid fa-check fs-5 text-info mt-n1 me-2" />
                    Unwavering belief and dedicated efforts to create standout
                    products.
                  </li>
                  <li className="d-flex pt-2 fs-6">
                    <i className="fa-solid fa-check fs-5 text-info mt-n1 me-2" />
                    Empowering industries through reshaping user experiences.
                  </li>
                  <li className="d-flex pt-2 fs-6">
                    <i className="fa-solid fa-check fs-5 text-info mt-n1 me-2" />
                    Setting the standard with quality, attention to detail, and
                    genuine passion.
                  </li>
                </ul>
                {/* <div className="position-relative mt-3 mb-lg-0 mb-4">
                  <button type="button" className="btn btn-info">
                    Check All Services
                  </button>
                </div> */}
              </div>
            </div>
            <div className="col-xxl-6 col-xl-7 col-lg-7 col-md-12 offset-xxl-1 offset-xl-1 offset-lg-1">
              <div className="row row-cols-1 row-cols-sm-2 g-xl-4 g-lg-4 g-4">
                <div className="col">
                  <div className="card border border-3 bg-whites rounded-4 px-2 py-3">
                    <div className="card-body">
                      <i className="fa-brands fa-fedora fs-1 text-primary mb-3" />
                      <h3 className="fs-5 font--medium">App Development</h3>
                      <p className="fs-sm mb-0">
                        Our expert team of developers meticulously plans, tests,
                        and crafts mobile applications with a focus on
                        innovation and creativity, ensuring an exceptional user
                        experience that leaves users fully satisfied.
                      </p>
                    </div>
                  </div>
                  <div className="card border border-3 bg-whites rounded-4 px-2 py-3 mt-4">
                    <div className="card-body">
                      <i className="fa-solid fa-lightbulb fs-1 text-success mb-3" />
                      <h3 className="fs-5 font--medium">Web Development</h3>
                      <p className="fs-sm">
                        Our web development expertise ensures surpassing user
                        expectations in the mobile age with accessible and
                        responsive applications.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col pt-lg-3">
                  <div className="card border border-3 bg-whites rounded-4 px-2 py-3">
                    <div className="card-body">
                      <i className="fa-solid fa-envelope-open-text fs-1 text-warning d-block mb-3" />
                      <h3 className="fs-5 font--medium">Growth Marketing</h3>
                      <p className="fs-sm">
                        Unlock the full potential of growth marketing with our
                        data-driven strategies. By defining metrics for
                        acquisition, retention, and conversion, we align your
                        technology and execute marketing campaigns that maximize
                        lifecycle value.
                      </p>
                    </div>
                  </div>
                  <div className="card border border-3 bg-whites rounded-4 px-2 py-3 mt-4">
                    <div className="card-body">
                      <i className="fa-brands fa-twitter fs-1 text-danger d-block mb-3" />
                      <h3 className="fs-5 font--medium">Product Design</h3>
                      <p className="fs-sm">
                        At our company, success is measured by the quality, not
                        quantity, of our product design. We delve deep into
                        extensive qualitative research to meet user needs,
                        delivering unparalleled interactivity and exceptional
                        experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ============================ High Class Features End ================================== */}
      {/* ============================ Features Start ================================== */}
      <section className="bg-light-success">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-7 col-lg-7 col-md-11 mb-3">
              <div className="sec-heading text-center">
                <div className="label text-success bg-light-success d-inline-flex rounded-4 mb-2 font--medium">
                  <span>Featured</span>
                </div>
                <h2 className="mb-1">Explore Top Services</h2>
                <p className="test-muted fs-6">
                  Unlocking Your Business Potential with Our Premier Services
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center g-4">
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="single-slide h-auto">
                <div className="card border-0 rounded-4 h-100 p-2">
                  <div className="card-img-top overflow-hidden">
                    <img
                      src="assets/img/web-development.webp"
                      className="img-fluid rounded-2"
                      alt="web development"
                    />
                  </div>
                  <div className="card-body">
                    <h3 className="font--bold mb-2 fs-5">Web Development</h3>
                    <ul className="simple-list">
                      <li className="mb-2">
                        Customized Solutions: Tailored Web Applications
                      </li>
                      <li className="mb-2">
                        Responsive Design: Adaptive Web UX
                      </li>
                      <li className="mb-2">
                        E-commerce Solution: Seamless Online Selling
                      </li>
                      <li className="mb-2">
                        API Development: Robust Data Connectivity
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="single-slide h-auto">
                <div className="card border-0 rounded-4 h-100 p-2">
                  <div className="card-img-top overflow-hidden">
                    <img
                      src="assets/img/app-development.webp"
                      className="img-fluid rounded-2"
                      alt="app development"
                    />
                  </div>
                  <div className="card-body">
                    <h3 className="font--bold mb-2 fs-5">App Development</h3>
                    <ul className="simple-list">
                      <li className="mb-2">
                        Customized Solutions: Tailored Mobile Apps
                      </li>
                      <li className="mb-2">
                        Intuitive User Experience: Seamless App UX
                      </li>
                      <li className="mb-2">
                        API Integration: Streamlined Data Connectivity
                      </li>
                      <li className="mb-2">
                        Cross-Platform : Multi-OS Compatibility
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="single-slide h-auto">
                <div className="card border-0 rounded-4 h-100 p-2">
                  <div className="card-img-top overflow-hidden">
                    <img
                      src="assets/img/growth-marketing.webp"
                      className="img-fluid rounded-2"
                      alt="growth marketing"
                    />
                  </div>
                  <div className="card-body">
                    <h3 className="font--bold mb-2 fs-5">Growth Marketing</h3>
                    <ul className="simple-list">
                      <li className="mb-2">
                        Data-Driven Strategies: Insights for Growth
                      </li>
                      <li className="mb-2">
                        Conversion Optimization: Maximizing ROI
                      </li>
                      <li className="mb-2">
                        Customer Journey Optimization: Guiding Growth
                      </li>
                      <li className="mb-2">
                        Performance Analytics: Measuring Impact
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="clearfix" />
      {/* ============================  Featurs End ================================== */}
      {/* ============================ Reason For Love Us Start ================================== */}
      <section>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <div className="position-relative exloi py-lg-0 py-4">
                <div className="exloi-caption">
                  {/* <div className="label text-success bg-light-success d-inline-flex rounded-4 mb-2 font--medium">
                    <span>Easy marketing</span>
                  </div> */}
                  <h2 className="mb-3 lh-base">
                    What We Are?
                    <br />
                  </h2>
                  <p className="mb-0 fs-5 fw-light">
                    Afterdoor Solutions is a dedicated development partner that
                    specializes in creating customized digital experiences. With
                    a mission to design, build, and innovate for your business,
                    we are passionate about kickstarting startups, fueling
                    enterprise software, and expanding horizons. Our expertise
                    lies in crafting truly memorable mobile apps and websites
                    that leave a lasting impact on users.
                  </p>
                  {/* <div className="lios-vrst">
                    <ul>
                      <li>
                        <div className="lios-parts">
                          <h2>
                            <span className="ctr me-1">70</span>B
                          </h2>
                          <h6 className="text-muted">Install Apps</h6>
                        </div>
                      </li>
                      <li>
                        <div className="lios-parts">
                          <h2>
                            <span className="ctr">42</span>
                          </h2>
                          <h6 className="text-muted">in Countries</h6>
                        </div>
                      </li>
                      <li>
                        <div className="lios-parts">
                          <h2>
                            <span className="ctr">57</span>B
                          </h2>
                          <h6 className="text-muted">Happy Users</h6>
                        </div>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <div className="position-relative exloi">
                <div className="position-relative">
                  <img
                    src="assets/img/about.webp"
                    className="img-fluid"
                    alt=""
                    style={{ borderRadius: "12rem 2rem 6rem 2rem" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="clearfix" />
      <Contact />
      <div className="clearfix" />

      {/* ============================ Footer Start ================================== */}
      <Footer />
      {/* ============================ Footer End ================================== */}
      {/* Log In Modal */}
    </div>
  );
};

export default Home;
