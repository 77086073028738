import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <section>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-7 col-lg-8 py-xl-5 py-4 text-center">
          <img
            src="/assets/img/logos/ads-logo-green.png"
            className="logo"
            alt="Afterdoor Solutions"
            style={{ width: 150 }}
          />
          <h1 className="extra-ft">
            4<span className="text-primary">0</span>4
          </h1>
          <h2 className="display-1 font--bold mb-2">Page not found</h2>
          <p className="fs-5 pb-4 mb-0 mb-sm-2 mb-2">
            The page you are looking for was moved, removed or might never
            existed.
          </p>
          <Link className="btn btn-lg btn-primary rounded-3 px-5" to={"/"}>
            Go to Homepage
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default NotFound;
