import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <footer className="footer skin-light-footer">
        <div>
          <div className="container">
            <div
              className="row animate__animated"
              data-animate="animate__fadeInUp"
            >
              <div className="col-lg-3 col-md-4">
                <div className="footer-widget">
                  <img
                    src="assets/img/logos/ads-logo-green.png"
                    className="img-footer"
                    alt=""
                  />
                  <div className="footer-add">
                    <p>
                      B1, E-253, Phase 8B, Industrial Area, <br />
                      Sector 74, Mohali, Punjab 160074
                    </p>
                  </div>
                  <div className="foot-socials">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/afterdoor"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa-brands fa-facebook" />
                        </a>
                      </li>
                      {/* <li>
                        <a href="/">
                          <i className="fa-brands fa-twitter" />
                        </a>
                      </li> */}
                      <li>
                        <a
                          href="https://www.instagram.com/afterdoor/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa-brands fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="footer-widget">
                  <h4 className="widget-title">The Company</h4>
                  <ul className="footer-menu">
                    <li>
                      <a href="/about-us">About Us</a>
                    </li>
                    <li>
                      <a href="/">Our Vision</a>
                    </li>
                    <li>
                      <a href="/">Contact Us</a>
                    </li>
                    <li>
                      <a href="/jobs">Join Our Team</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="footer-widget">
                  <h4 className="widget-title">Our Services</h4>
                  <ul className="footer-menu">
                    <li>
                      <a href="/web-development">Web Development</a>
                    </li>
                    <li>
                      <a href="/design">Design</a>
                    </li>
                    <li>
                      <a href="/">Mobile Application</a>
                    </li>
                    <li>
                      <a href="/">Quality Assurance</a>
                    </li>
                    <li>
                      <a href="/">Research & Development</a>
                    </li>
                    <li>
                      <a href="/">Cloud Management</a>
                    </li>
                    <li>
                      <a href="/">Cyber Security</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="footer-widget">
                  <h4 className="widget-title">Case Studies</h4>
                  <ul className="footer-menu">
                    <li>
                      <a href="/">Case Study 1</a>
                    </li>
                    <li>
                      <a href="/">Case Study 2</a>
                    </li>
                    <li>
                      <a href="/">Case Study 3</a>
                    </li>
                    <li>
                      <a href="/">Case Study 4</a>
                    </li>
                    <li>
                      <a href="/">Case Study 5</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-widget">
                  <h4 className="widget-title">About</h4>
                  <p>
                    At Afterdoor Solutions, we're more than just a development
                    partner – we're architects of tailored digital experiences.
                    With a resolute commitment to igniting startups, energizing
                    enterprise software, and pushing boundaries, we're driven by
                    a passion for innovation. Through innovation and dedication,
                    we're here to transform ideas into impactful realities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div
                className="col-xl-4 col-lg-5 col-md-5 animate__animated"
                data-animate="animate__fadeInLeft"
              >
                <p className="mb-0">
                  © {year} Afterdoor Solutions ® . All Rights Reserved.
                </p>
              </div>
              <div
                className="col-xl-8 col-lg-7 col-md-7 animate__animated"
                data-animate="animate__fadeInRight"
              >
                <div className="job-info-count-group">
                  <div className="single-jb-info-count">
                    <div className="jbs-y7">
                      <h5 className="ctr">10</h5>
                      <span className="theme-2-cl">+</span>
                    </div>
                    <div className="jbs-y5">
                      <p>Years</p>
                    </div>
                  </div>
                  <div className="single-jb-info-count">
                    <div className="jbs-y7">
                      <h5 className="ctr">20</h5>
                      <span className="theme-2-cl">+</span>
                    </div>
                    <div className="jbs-y5">
                      <p>Services</p>
                    </div>
                  </div>
                  <div className="single-jb-info-count">
                    <div className="jbs-y7">
                      <h5 className="ctr">100</h5>
                      <span className="theme-2-cl">+</span>
                    </div>
                    <div className="jbs-y5">
                      <p>Happy Customers</p>
                    </div>
                  </div>
                  <div className="single-jb-info-count">
                    <div className="jbs-y7">
                      <h5 className="ctr">200</h5>
                      <span className="theme-2-cl">+</span>
                    </div>
                    <div className="jbs-y5">
                      <p>Projects</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
