import React, { useState } from "react";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [isBtnLoading, setIsButtonLoading] = useState(false);
  const [projectDescription, setProjectDescription] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    // Your API endpoint URL
    const apiEndpoint =
      "https://zohimiao33q5mrerf7pocpt6su0eoawi.lambda-url.ap-south-1.on.aws/";
    const formData = {
      name: name,
      email: email,
      phone: phone,
      company: company,
      content: projectDescription,
    };

    setIsButtonLoading(true);

    fetch(apiEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setSuccessMessage(
          "Thank you for getting in touch! We will be in contact with you shortly. "
        );
        // You can also reset the form fields here
        setName("");
        setEmail("");
        setPhone("");
        setCompany("");
        setProjectDescription("");
        setIsButtonLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsButtonLoading(false);
      });
  };
  return (
    <section className="bg-dark position-relative" id="contact-us">
      <div className="position-absolute top-0 start-0 me-4 mt-3 z-0">
        <img
          src="assets/img/shape-1-soft-light.svg"
          alt="img"
          className="img-fluid"
          width={700}
        />
      </div>
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div
            className="col-xl-5 col-lg-5 col-md-12 mb-3 animate__animated"
            data-animate="animate__bounceIn"
          >
            <div className="explior position-relative p-lg-0 py-4">
              <div className="label text-light bg-success d-inline-flex rounded-4 mb-2 font--medium">
                <span>Get Your Quotes</span>
              </div>
              <h2 className="mb-3 lh-base text-light">
                Build Your Brand Better
                <br />
                Let's Meet Us
              </h2>
              <p className="mb-0 fs-5 text-light opacity-75 fs-mob">
                We invite you to meet our passionate team of experts who are
                ready to collaborate and bring your ideas to life. Let's connect
                and discuss how we can create exceptional solutions for your
                business.
              </p>
            </div>
            <div className="explior-arrow d-none d-lg-block mt-5 ms-5">
              <img src="assets/img/arrow-line.png" alt="img" width={200} />
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-6 col-md-12 animate__animated"
            data-animate="animate__bounceIn"
          >
            <div className="card border-0 rounded-5 bg-whites position-relative py-xl-4 px-xl-4 px-lg-4 px-md-4 p-2">
              <div className="position-absolute top-0 end-0 me-4 mt-3 z-0">
                <img src="assets/img/award-2.png" alt="img" width={80} />
              </div>
              <div className="card-body">
                <form className="mx-auto" onSubmit={handleSubmit}>
                  <div className="text-left mb-2">
                    <div className="rounded-5 px-3 py-1 font--medium text-success bg-light-success d-inline-flex justify-content-center m-auto">
                      Project Quotes
                    </div>
                  </div>
                  <h2 className="pb-4">Get Your Project Quotes</h2>
                  <div className="row g-4">
                    <div className="col-sm-6">
                      <label className="form-label opacity-75">Name</label>
                      <input
                        className="form-control lg"
                        type="text"
                        placeholder="Your name"
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label opacity-75">Email</label>
                      <input
                        className="form-control lg"
                        type="email"
                        placeholder="Email address"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label opacity-75">Phone</label>
                      <input
                        className="form-control lg"
                        type="text"
                        placeholder="Phone number"
                        value={phone}
                        required
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label opacity-75">Company</label>
                      <input
                        className="form-control lg"
                        type="text"
                        placeholder="Your company name"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-12">
                      <label className="form-label opacity-75">
                        Explain your Project
                      </label>
                      <textarea
                        className="form-control"
                        rows={6}
                        placeholder="Your Project Description....."
                        value={projectDescription}
                        required
                        onChange={(e) => setProjectDescription(e.target.value)}
                      />
                    </div>

                    <div className="col-sm-12 pt-2">
                      <button
                        className="btn btn-lg btn-success font--medium px-xl-5"
                        type="submit"
                        disabled={isBtnLoading}
                      >
                        {isBtnLoading ? "Sending Mail ..." : "Send a request"}
                      </button>
                    </div>
                    {successMessage !== "" && (
                      <div className="alert alert-success returnmessage">
                        {successMessage}
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
