import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home";
import Contact from "./Components/Contact";
import NotFound from "./Components/NotFound";
import AboutUs from "./Components/AboutUs";
import SmoothScroll from "./Components/SmoothScroll";
import Jobs from "./Components/Jobs";
import WebDevelopment from "./Components/services/WebDevelopment";
import Design from "./Components/services/Design";

function App() {
  return (
    <Router>
      <SmoothScroll>
        <div className="App">
          {/* <div id="preloader">
          <div className="preloader">
            <span />
            <span />
          </div>
        </div> */}
          <div id="main-wrapper">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/web-development" element={<WebDevelopment />} />
              <Route path="/design" element={<Design />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </SmoothScroll>
    </Router>
  );
}

export default App;
