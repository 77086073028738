import React from "react";
import Navigation from "../Sections/Navigation";
import Footer from "../Sections/Footer";
import Contact from "./Contact";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <title>About Us - Afterdoor Solutions</title>
        <meta
          name="description"
          content="Welcome to Afterdoor Solutions, where technology meets innovation. Our mission is to be your partner in embracing technological advancement and equipping your business with tailored solutions to thrive in today's digital landscape."
        />
      </Helmet>
      <div className="clearfix" />
      <Navigation />
      <section
        className="bg-cover"
        style={{ background: "url(/assets/img/about-page.webp)no-repeat" }}
        data-overlay={6}
      >
        <div className="ht-80" />
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-12">
              <h1 className="text-light mb-4">
                We're Here for
                <br />
                Solving your Solutions
              </h1>
              <p className="fs-5 fw-light text-light mb-4">
                Ignite Your Business Growth with State-of-the-Art Software and
                Mobile App Capabilities, Designed to Foster Success in the
                Modern Digital Landscape.
              </p>
              <a href="#contact-us" className="btn btn-primary px-5">
                Contact Us
              </a>
            </div>
          </div>
        </div>
        <div className="ht-80" />
      </section>

      <section>
        <div className="container">
          <div className="row justify-content-between align-items-center mb-5">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="position-relative exloi">
                <div className="position-relative pe-4 pb-4">
                  <img
                    src="/assets/img/hands.webp"
                    className="img-fluid rounded-5"
                    alt=""
                  />
                </div>
                {/* <div className="position-absolute bottom-0 end-0">
                  <img
                    src="assets/img/img-3.png"
                    className="img-fluid rounded-2 shadow"
                    width={230}
                    alt=""
                  />
                </div> */}
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <div className="position-relative exloi py-lg-0 py-4">
                <div className="exloi-caption">
                  <div className="label text-success bg-light-success d-inline-flex rounded-4 mb-2 font--medium">
                    <span>Let's Introduce</span>
                  </div>
                  <h2 className="display-5 font--bold lh-base mb-3">
                    Who We're &amp; Mission
                  </h2>
                  <p className="mb-0 fs-5 fw-light mb-3">
                    Welcome to Afterdoor Solutions, where technology meets
                    innovation. As a dedicated team, we are committed to
                    propelling your business towards success in the digital age.
                    Our company specializes in creating impactful mobile apps,
                    impressive websites, robust software solutions, and
                    efficient cloud management services, all designed to empower
                    businesses like yours.
                  </p>
                  <p className="mb-0 fs-5 fw-light">
                    At Afterdoor Solutions, our mission is simple yet powerful:
                    to be your partner in embracing technological advancement.
                    We are driven by the belief that every business, regardless
                    of its size or industry, deserves the tools to thrive in
                    today's digital landscape. By providing tailored,
                    forward-looking solutions, we aim to equip you with the
                    means to not only navigate the digital era but to conquer
                    it. Your success is our motivation, and we're dedicated to
                    helping you harness the full potential of technology to
                    drive your business forward.
                  </p>
                  <div className="exloi-link mt-4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-seegreen pt-4 pb-4">
        <div className="container">
          <div className="row g-4">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div className="d-flex align-items-center">
                <div className="exljk-icon text-warning fs-2">
                  <i className="fa-solid fa-code" />
                </div>
                <div className="exljk-caps ps-3">
                  <h5 className="text-light fw-semibold">
                    Innovative Development
                  </h5>
                  <p className="m-0 text-light lh-base opacity-75">
                    Unleashing the potential of technology, we create solutions
                    that push boundaries and redefine possibilities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div className="d-flex align-items-center">
                <div className="exljk-icon text-warning fs-2">
                  <i className="fa-solid fa-pen-nib" />
                </div>
                <div className="exljk-caps ps-3">
                  <h5 className="text-light fw-semibold">
                    User-Centric Design
                  </h5>
                  <p className="m-0 text-light lh-base opacity-75">
                    Putting users at the heart of our designs, we craft
                    experiences that resonate, inspire, and leave a lasting
                    impact.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div className="d-flex align-items-center">
                <div className="exljk-icon text-warning fs-2">
                  <i className="fa-solid fa-dna" />
                </div>
                <div className="exljk-caps ps-3">
                  <h5 className="text-light fw-semibold">
                    Agile Problem-Solving
                  </h5>
                  <p className="m-0 text-light lh-base opacity-75">
                    Adapting swiftly to challenges, we provide precise solutions
                    that drive your business forward with efficiency and
                    effectiveness.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="clearfix" />
      <Contact />
      <div className="clearfix" />

      <Footer />
    </div>
  );
};

export default AboutUs;
